import axios from "axios";
import React, { useEffect, useState } from "react";
import Img01 from "../assets/BusinessCentral01.png";
import Img02 from "../assets/BusinessCentral02.png";
import FooterTable from "./FooterTable";

const BusinessCentralTable = () => {

  const [infoData, setInfoData] = useState([]);

  useEffect(() => {
    const fetchInfoText = async (article, app, DK) => {
      try {
        const response = await axios.get(
          `https://engine.netsupport.dk:7080/account/v1/getinfotext?article=${article}&app=${app}&Language=${DK}`
        );
        return response.data.memo;
      } catch (error) {
        console.error("Error fetching info text:", error.message);
        throw error;
      }
    };

    const parameterSets = [
      { article: "bc.1.1", app: "WEB", DK: "DK" },
      { article: "bc.1.2", app: "WEB", DK: "DK" },
      { article: "bc.2.1", app: "WEB", DK: "DK" },
      { article: "bc.2.2", app: "WEB", DK: "DK" },
    ];

    const fetchDataForAllParameters = async () => {
      const fetchPromises = parameterSets.map((params) => fetchInfoText(params.article, params.app, params.DK));
      const results = await Promise.all(fetchPromises);
      setInfoData(results);
    };

    fetchDataForAllParameters();
  }, []);

  return (
    <div className="text-[#2D838E] pt-20 md:pt-28 lg:pt-40">
      {/* hero part */}
      <div className="mb-4 lg:mb-0">
        <div className="hero hidden lg:block max-w-screen-xl mx-auto mb-6">
          <div className="hero-content pb-0 flex-col justify-between lg:flex-row-reverse">
            <img src={Img01} className="flex-auto max-w-[40%]" alt="" />
            <div className="flex-auto max-w-[50%]">
              <h1 className="text-4xl font-bold leading-tight">{infoData[0]}</h1>
              <pre className="my-8 py-1 pl-4 border-l-[3px] border-l-amber-600">{infoData[1]}</pre>
            </div>
          </div>
        </div>

        <div className="hero lg:hidden">
          <div className="hero-content flex-col lg:flex-row-reverse">
            <h1 className="text-4xl text-center font-bold leading-tight">{infoData[0]}</h1>
            <div className="flex-auto flex items-center flex-col">
              <img src={Img01} className="max-w-[90%] md:max-w-[60%]" alt="" />
              <pre className="my-8 py-1">{infoData[1]}</pre>
            </div>
          </div>
        </div>
      </div>

      {/* second part */}
      <div className="bg-[#e0eced] dark:bg-[#272f44] py-12 mb-4">
        <div className="max-w-screen-xl mx-auto hero">
          <div className="hero-content flex-col lg:flex-row lg:gap-16">
            <img src={Img02} className="flex-1 lg:max-w-lg w-full" alt="" />
            <div className="flex-1 mt-3 lg:mt-0">
              <h1 className="text-4xl lg:text-4xl font-bold leading-tight">{infoData[2]}</h1>
              <pre className="py-3 lg:py-6 lg:pr-16">{infoData[3]}</pre>
            </div>
          </div>
        </div>
      </div>
      <FooterTable />
    </div>
  );
};

export default BusinessCentralTable;
