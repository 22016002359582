import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";

const languageOptions = [
  {
    id: "dk",
    name: "Danish",
    flagimg: "https://i.ibb.co/8j1n195/dk.jpg",
  },
  {
    id: "en",
    name: "English",
    flagimg: "https://i.ibb.co/D9bJRhQ/eng.png",
  },
  {
    id: "gr",
    name: "German",
    flagimg: "https://i.ibb.co/DYs98MX/german.png",
  },
  {
    id: "nl",
    name: "Netherland",
    flagimg: "https://i.ibb.co/bKcfpGJ/natherland.jpg",
  },
];

const defaultLangFlag = <img src={languageOptions[1].flagimg} height="30" width="30" alt="nope" />;

const LoginPage = () => {
  const [cssDisplay, setCssDisplay] = useState("none");
  const [langFlag, setLangFlag] = useState(defaultLangFlag);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const showDropdown = () => {
    if (cssDisplay === "none") {
      setCssDisplay("block");
    } else {
      setCssDisplay("none");
    }
  };

  const selectListItem = (event) => {
    setCssDisplay("none");
    setLangFlag(<img src={event.target.src} className="w-8" alt="nope" />);
  };

  return (
    <div className="flex bg-slate-100">
      <div className="hidden lg:flex w-4/6 h-screen justify-center items-center relative">
        <img
          className="w-52 absolute top-10 left-10"
          src="https://integreat.dk/wp-content/uploads/2022/11/InteGreat-logo.png"
          alt="logo"
        />
        <div className="w-4/6">
          <img src="https://ig.integreat.dk/img/forIG5_light.39848a03.png" alt="" />
        </div>
      </div>
      <div className="flex justify-center items-center mx-auto h-screen px-16 bg-white w-full lg:w-2/6">
        <div className="w-full max-w-md lg:max-w-sm">
          <h2 className="text-4xl text-amber-900">
            Welcome to
            <br />
            InteGreat for Rackbeat!
          </h2>

          <div className="mt-3 relative">
            <button onClick={showDropdown} className="flex items-center gap-3">
              {langFlag} <BsChevronDown />
            </button>

            <ul
              style={{ display: cssDisplay }}
              className="bg-white shadow-lg rounded-md p-3 pb-1 w-fit absolute left-8"
            >
              {languageOptions.map((lang) => (
                <li id={lang.id} key={lang.id} disabled className="flex z-50 gap-2 mb-2">
                  <img onClick={selectListItem} src={lang.flagimg} height="30" width="30" alt="flagpic" id={lang.id} />
                  {lang.name}
                </li>
              ))}
            </ul>
          </div>

          <p className="my-5 text-lg">Please sign-in to your account</p>

          <div className="flex flex-col gap-4">
            <div>
              <p className="text-sm mb-1">Email or Username</p>
              <input
                type="text"
                placeholder="john@example.com"
                className="input border-slate-300 w-full rounded-md focus:outline-0 focus:border-green-800"
              />
            </div>

            <input
              type="text"
              placeholder="............"
              className="input border-slate-300 w-full rounded-md focus:outline-0 focus:border-green-800"
            />

            <p>
              <Link to="" className="btn bg-green-800 text-white hover:bg-green-900 w-full">
                Sign in
              </Link>
            </p>
          </div>

          <p className="text-xl font-medium text-slate-600 mt-6 text-center py-0">
            New on our platform?{" "}
            <Link to="/registration" className="btn bg-green-800 text-white hover:bg-green-900 py-0 px-6">
              create
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
