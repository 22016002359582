import axios from "axios";
import React, { useEffect, useState } from "react";
import img02 from "../assets/Home02.png";
import img03 from "../assets/Home03.png";

const Section2 = () => {
  const [infoData, setInfoData] = useState([]);

  useEffect(() => {
    const fetchInfoText = async (article, app, DK) => {
      try {
        const response = await axios.get(
          `https://engine.netsupport.dk:7080/account/v1/getinfotext?article=${article}&app=${app}&Language=${DK}`
        );
        return response.data.memo;
      } catch (error) {
        console.error("Error fetching info text:", error.message);
        throw error;
      }
    };

    const parameterSets = [
      { article: "main.2.1", app: "WEB", DK: "DK" },
      { article: "main.2.2", app: "WEB", DK: "DK" },
      { article: "main.3.1", app: "WEB", DK: "DK" },
      { article: "main.3.2", app: "WEB", DK: "DK" },
      { article: "main.4.1", app: "WEB", DK: "DK" },
      { article: "main.4.2", app: "WEB", DK: "DK" },
      { article: "main.4.3", app: "WEB", DK: "DK" },
      { article: "main.4.4", app: "WEB", DK: "DK" },
    ];

    const fetchDataForAllParameters = async () => {
      const fetchPromises = parameterSets.map((params) => fetchInfoText(params.article, params.app, params.DK));
      const results = await Promise.all(fetchPromises);
      setInfoData(results);
    };

    fetchDataForAllParameters();
  }, []);

  return (
    <div className="">
      <div className="bg-[#e0eced] dark:bg-[#272f44] px-3 py-12 lg:py-28">
        <div className="hero max-w-screen-xl mx-auto">
          <div className="hero-content flex-col lg:flex-row lg:gap-16">
            <img src={img02} className="flex-1 lg:max-w-lg w-full" alt="" />
            <div className="flex-1">
              <h1 className="text-4xl lg:text-4xl font-bold text-[#2D838E] leading-tight">{infoData[0]}</h1>
              <pre className="py-6 lg:pr-16 text-[#2D838E]">{infoData[1]}</pre>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-screen-xl mx-auto px-3 py-12 lg:py-28">
        <div className="hero pt-8 lg:pt-14">
          <div className="hero-content flex-col lg:flex-row-reverse lg:gap-16">
            <img src={img03} className="flex-1 lg:max-w-lg w-full" alt="" />
            <div className="flex-1">
              <h1 className="text-4xl lg:text-4xl font-bold text-[#2D838E] leading-tight">{infoData[2]}</h1>
              <pre className="py-6 lg:pr-16 text-[#2D838E]">{infoData[3]}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
