import React from "react";
import FooterTable from "./FooterTable";

const DineroTable = () => {

  return (
    <div className="text-[#2D838E] pt-24 lg:pt-32">
      <FooterTable />
    </div>
  );
};

export default DineroTable;
