import React, { useState } from "react";
import { Link } from "react-router-dom";
import { losninger } from "../static/data";
import Switcher from "../Switcher";
import inteGreatLogo from "../assets/InteGreat-logo.png"

const Navbar = () => {
    const [openMenu1, setOpenMenu1] = useState(false);
    const [openLogin, setOpenLogin] = useState(false);

    const handleMenu1 = () => {
        setOpenMenu1(!openMenu1);
        setOpenLogin(false);
    };

    const handleLogin = () => {
        setOpenLogin(!openLogin);
        setOpenMenu1(false);
    };

    return (
        <div className="border-0 md:border-b-2 border-b-[#2c848e] fixed top-0 left-0 z-50 w-full bg-[#f5f5f5] lg:bg-white dark:bg-[#272f44] text-[#2c848e]">
            <div className="navbar max-w-screen-xl mx-auto py-6">
                <div className="flex-1">
                    <div className="dropdown pr-2.5">
                        <label tabIndex={0} className="cursor-pointer lg:hidden text-gray-400">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-7 w-7"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h8m-8 6h16"
                                />
                            </svg>
                        </label>
                        <ul
                            tabIndex={0}
                            className="dropdown-content mt-3 z-[1] p-2 shadow rounded-md w-72 bg-[#f5f5f5] dark:bg-[#151c2f]"
                        >
                            <li className="border-b-[1px] border-[#2c848e] p-2 ">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="border-b-[1px] border-[#2c848e] p-2 ">
                                <Link to="/partners">Partners</Link>
                            </li>
                            <li onClick={handleMenu1} className="border-b-[1px] border-[#2c848e] p-2">
                                <Link to="">Løsninger</Link>
                                <ul
                                    className={`z-[1] py-1 absolute left-0 shadow text-white rounded-md w-52 mt-4 bg-[#2c848e] font-normal ${
                                        openMenu1 ? "visible" : "invisible"
                                    }`}
                                >
                                    {losninger.map((los, idx) => (
                                        <li
                                            key={idx}
                                            className={`hover:text-[#f29650] py-2 px-4 ${
                                                idx === losninger.length - 1 ? "" : "border-b-[1px] border-white"
                                            }`}
                                        >
                                            <Link to={`${los.url}`}>{los.name}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li className="border-b-[1px] border-[#2c848e] p-2 ">
                                <Link to="/priser">Priser</Link>
                            </li>
                            <li className="p-2 ">
                                <Link to="/omos">Om Os</Link>
                            </li>
                            <li className="p-2 ">
                                <Link to="/kontakt">Kontakt</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="flex gap-4 md:gap-7 items-center">
                        <Link to="/">
                            <img
                                className="w-40 md:w-48 lg:w-64 dark:brightness-0 dark:invert"
                                src={inteGreatLogo}
                                alt="logo"
                            />
                        </Link>
                        <Switcher />
                    </div>
                </div>
                <div className="flex-none uppercase">
                    <div className="hidden lg:flex">
                        <ul className="text-md  cursor-default flex gap-9 font-medium">
                            <li className="hover:text-[#f29650]">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="hover:text-[#f29650]">
                                <Link to="/partners">Partners</Link>
                            </li>
                            <li onClick={handleMenu1} className="hover:text-[#f29650] relative">
                                <Link to="">Løsninger</Link>
                                <ul
                                    className={`z-[1] py-1 absolute left-0 shadow text-white rounded-md w-52 mt-4 bg-[#2c848e] font-normal ${
                                        openMenu1 ? "visible" : "invisible"
                                    }`}
                                >
                                    {losninger.map((los, idx) => (
                                        <li
                                            key={idx}
                                            className={`hover:text-[#f29650] py-2 px-4 ${
                                                idx === losninger.length - 1 ? "" : "border-b-[1px] border-white"
                                            }`}
                                        >
                                            <Link to={`${los.url}`}>{los.name}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li className="hover:text-[#f29650]">
                                <Link to="/priser">Priser</Link>
                            </li>
                            <li className="hover:text-[#f29650]">
                                <Link to="/omos">Om os</Link>
                            </li>
                            <li className="hover:text-[#f29650]">
                                <Link to="/kontakt">Kontakt</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="ml-9">
                        <label
                            onClick={handleLogin}
                            className=" font-medium text-md hover:text-[#f29650] cursor-pointer relative"
                        >
                            Login
                            <ul
                                className={`z-[1] py-1 absolute right-0 shadow text-white rounded-md w-72 mt-4 bg-[#2c848e] font-normal ${
                                    openLogin ? "visible" : "invisible"
                                }`}
                            >
                                <li className="hover:text-[#f29650] border-b-[1px] border-white py-2 px-4">
                                    <Link to="https://login.integreat.dk">Login Til InteGreat Classic</Link>
                                </li>
                                <li className="hover:text-[#f29650] border-b-[1px] border-white py-2 px-4">
                                    <Link to="https://ig.integreat.dk">Login Til InteGreat V5</Link>
                                </li>
                                <li className="hover:text-[#f29650] py-2 px-4">
                                    <Link to="https://portal.integreat.dk">Login til InteGreat Kundeportal</Link>
                                </li>
                            </ul>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
