import React, { useState } from "react";
import { Link } from "react-router-dom";
import { losninger } from "../static/data";
import footerLogo from "../assets/InteGreat-logo.png"

const year = new Date().getFullYear();

const Footer = () => {
  const [openMenu1, setOpenMenu1] = useState(false);

  const handleMenu1 = () => {
    setOpenMenu1(!openMenu1);
  };

  return (
    <div className="bg-[#e0eced] dark:bg-[#272f44]">
      <div className="max-w-screen-xl mx-auto text-[#2D838E] pt-10 lg:pb-10 lg:pt-16 lg:px-4">
        <div className="dropdown flex justify-center">
          <ul className="text-md text-[#2D838E] cursor-default gap-9 font-medium hidden lg:flex">
            <li className="hover:text-[#f29650]">
              <Link to="/partners">Partners</Link>
            </li>
            <li onClick={handleMenu1} className="hover:text-[#f29650] relative">
              <Link to="">Løsninger</Link>
              <ul
                className={`z-[1] py-1 absolute left-0 bottom-10 shadow text-white rounded-md w-52 mt-4 bg-[#2D838E] font-normal ${
                  openMenu1 ? "visible" : "invisible"
                }`}
              >
                {losninger.map((los, idx) => (
                  <li
                    key={idx}
                    className={`hover:text-[#f29650] py-2 px-4 ${
                      idx === losninger.length - 1 ? "" : "border-b-[1px] border-white"
                    }`}
                  >
                    <Link to={`${los.url}`}>{los.name}</Link>
                  </li>
                ))}
              </ul>
            </li>
            <li className="hover:text-[#f29650]">
              <Link to="/priser">Priser</Link>
            </li>
            <li className="hover:text-[#f29650]">
              <Link to="/omos">Om os</Link>
            </li>
          </ul>
        </div>

        <hr className="border-b-[2px] border-t-0 border-b-[#f29650] mt-5 mb-12 md:my-12" />

        <div className="lg:justify-between items-center hidden md:flex md:justify-around pb-20">
          <div className="leading-relaxed">
            <p>InteGreat Aps</p>
            <p>CVR: 40743618</p>
            <p>
              <Link to="/databehandleraftale" className="font-bold hover:underline">
                Databehandleraftale (GDPR)
              </Link>
            </p>
            <p>
              <Link to="/abonnementsvilkar" className="font-bold hover:underline">
                Abonnementsvilkår for InteGreat
              </Link>
            </p>
          </div>
          <div>
            <img
              className="lg:w-[22rem] md:w-60 dark:brightness-0 dark:invert"
              src={footerLogo}
              alt="log"
            />
          </div>
          <div className="leading-relaxed text-right">
            <p>Telefon</p>
            <p>
              <a href="tel:+45 87361113" className="font-bold hover:underline">
                +45 87361113
              </a>
            </p>
            <p>E-mail</p>
            <p>
              <a href="mailto:support@integreat.dk" className="font-bold hover:underline">
              support@integreat.dk
              </a>
            </p>
          </div>
        </div>

        <div className="flex md:hidden flex-col items-center pb-20">
          <div>
            <Link to="/">
              <img
                className="w-72 dark:brightness-0 dark:invert"
                src={footerLogo}
                alt="logo"
              />
            </Link>
          </div>
          <div className="leading-relaxed my-12 text-center">
            <p>InteGreat Aps</p>
            <p>CVR: 40743618</p>
            <p>
              <Link to="" className="font-bold hover:underline">
                Databehandleraftale (GDPR)
              </Link>
            </p>
            <p>
              <Link to="" className="font-bold hover:underline">
                Abonnementsvilkår for InteGreat
              </Link>
            </p>
          </div>
          <div className="leading-relaxed text-center">
            <p>Telefon</p>
            <p>
              <a href="tel:+45 87361113" className="font-bold hover:underline">
                +45 87361113
              </a>
            </p>
            <p>E-mail</p>
            <p>
              <a href="mailto:support@integreat.dk" className="font-bold hover:underline">
                support@integreat.dk
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="text-center bg-[#2D838E] text-white py-5">
        <p className="text-sm">Copyright InteGreat Aps {year}</p>
      </div>
    </div>
  );
};

export default Footer;
