import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import image06 from "../assets/Home06.png"

const Section5 = () => {
  const [infoData, setInfoData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInfoText = async (article, app, DK) => {
      try {
        const response = await axios.get(
          `https://engine.netsupport.dk:7080/account/v1/getinfotext?article=${article}&app=${app}&Language=${DK}`
        );
        return response.data.memo;
      } catch (error) {
        console.error("Error fetching info text:", error.message);
        throw error;
      }
    };

    const parameterSets = [
      { article: "main.7.1", app: "WEB", DK: "DK" },
      { article: "main.7.2", app: "WEB", DK: "DK" },
      { article: "main.8.1", app: "WEB", DK: "DK" },
      { article: "main.8.2", app: "WEB", DK: "DK" },
    ];

    const fetchDataForAllParameters = async () => {
      const fetchPromises = parameterSets.map((params) => fetchInfoText(params.article, params.app, params.DK));
      const results = await Promise.all(fetchPromises);
      setInfoData(results);
    };

    fetchDataForAllParameters();
  }, []);

  const handleClick = () => {
    navigate("/kontakt");
  };

  return (
    <div className="bg-[#e0eced] dark:bg-[#272f44] py-10 md:py-16 lg:py-28">
      <div className="max-w-screen-xl mx-8 md:mx-14 lg:mx-auto text-[#2D838E]">
        <div className="hero">
          <div className="hero-content flex-col-reverse md:flex-row-reverse">
            <img
              src={image06}
              className="max-w-full md:max-w-md lg:max-w-full flex-auto mt-8 md:mt-0"
              alt=""
            />
            <div className="flex-auto">
              <h3 className="font-bold text-lg mb-5">Overlad teknikken til os</h3>
              <h1 className="text-4xl lg:text-4xl font-bold leading-tight mb-10">{infoData[2]}</h1>
              <pre className="">{infoData[3]}</pre>

              <button
                onClick={handleClick}
                className="btn btn-primary rounded-full bg-[#f29650] text-white border-[#f29650] border-2 px-10 hover:bg-[#ffa954] hover:border-[#f29650] mt-28"
              >
                KONTAKT OS FOR AT HØRE NÆRMERE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
