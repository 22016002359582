import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import image01 from "../assets/Home01.png";

const Hero = () => {
  const [infoData, setInfoData] = useState([]);

  useEffect(() => {
    const fetchInfoText = async (article, app, DK) => {
      try {
        const response = await axios.get(
          `https://engine.netsupport.dk:7080/account/v1/getinfotext?article=${article}&app=${app}&Language=${DK}`
        );
        return response.data.memo;
      } catch (error) {
        console.error("Error fetching info text:", error.message);
        throw error;
      }
    };

    const parameterSets = [
      { article: "main.1.1", app: "WEB", DK: "DK" },
      { article: "main.1.2", app: "WEB", DK: "DK" },
    ];

    const fetchDataForAllParameters = async () => {
      const fetchPromises = parameterSets.map((params) => fetchInfoText(params.article, params.app, params.DK));
      const results = await Promise.all(fetchPromises);
      setInfoData(results);
    };

    fetchDataForAllParameters();
  }, []);

  return (
    <div className="pt-24 md:pt-32 lg:pt-44 pb-8 mb-4 lg:mb-0">
      <div className="hero hidden lg:block max-w-screen-xl mx-auto">
        <div className="hero-content flex-col lg:flex-row-reverse">
          <img
            src={image01}
            className="flex-auto max-w-[50%]"
            alt=""
          />
          <div className="flex-auto max-w-[50%]">
            <h1 className="text-4xl font-bold leading-tight text-[#2D838E]">{infoData[0]}</h1>
            <pre className="my-8 py-1 pl-4 border-l-[3px] border-l-amber-600 text-[#2D838E]">{infoData[1]}</pre>

            <div className="grid gap-4 grid-cols-1 lg:grid-cols-2">
              <Link
                to="/kontakt"
                className="btn btn-primary rounded-full bg-[#f29650] text-white border-[#f29650] border-2 uppercase px-10 hover:bg-[#ffa954] hover:border-[#f29650]"
              >
                Kontakt os
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="hero lg:hidden max-w-screen-xl mx-auto">
        <div className="hero-content flex-col lg:flex-row-reverse mx-6">
          <h1 className="text-4xl text-center font-bold leading-tight text-[#2D838E]">{infoData[0]}</h1>
          <div className="flex-auto">
            <img
              src={image01}
              className="flex-auto w-full"
              alt=""
            />
            <pre className="my-8 py-1 pl-4 text-center text-[#2D838E]">{infoData[1]}</pre>

            <div className="flex items-center justify-center">
              <button className="btn btn-primary rounded-full bg-[#f29650] text-white border-[#f29650] border-2 px-8 hover:bg-[#ffa954] hover:border-[#f29650]">
                LÆS MERE HER
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
