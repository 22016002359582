import React from "react";
import { BsCheckLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import image07 from '../assets/Home07.png'

const Section7 = () => {
  return (
    <div className="max-w-screen-xl mx-auto my-20">
      <div className="hero text-[#2D838E]">
        <div className="hero-content flex-col lg:flex-row gap-8 items-center">
          <img
            src={image07}
            className="w-2/5 hidden lg:block"
            alt=""
          />
          <div className="lg:w-3/5">
            <h1 className="text-4xl lg:text-4xl font-bold">Automatisér manuelle handlinger med InteGreat</h1>

            <ul className="my-10 leading-loose">
              <li className="flex gap-2 items-center mb-4">
                <BsCheckLg /> Fleksibel og tilpasset din virksomhed
              </li>
              <li className="flex gap-2 items-center mb-4">
                <BsCheckLg /> Forbinder dine systemer
              </li>
              <li className="flex gap-2 items-center">
                <BsCheckLg /> Vi klarer teknikken
              </li>
            </ul>

            <p className="font-bold">
              <Link
                to="/kontakt"
                className="btn btn-primary rounded-full bg-[#f29650] text-white border-[#f29650] border-2 uppercase px-10 hover:bg-[#ffa954] hover:border-[#f29650]"
              >
                Kontakt os
              </Link>
              <span className="ml-3">for en uforpligtende snak</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section7;
