import React from "react";
import pdfFile from "../assets/files/AbonnementsvilkårInteGreat.pdf";

const Abonnementsvilkår = () => {
  return (
    <div className="pt-24 md:pt-28 lg:pt-32 mb-4 max-w-screen-2xl mx-auto h-[calc(100vh)]">
      <iframe title="Abonnementsvilkår" src={pdfFile} frameborder="0" height="100%" width="100%"></iframe>
    </div>
  );
};

export default Abonnementsvilkår;
