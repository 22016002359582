import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import OmOs from "../components/OmOs";
import { useLocation } from "react-router-dom";

const OmOsPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Navbar />
      <OmOs />
      <Footer />
    </div>
  );
};

export default OmOsPage;
