/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useEffect, useState } from "react";

const YearlyPriser = ({
    text,
    selectedProducts,
    system,
    period,
    setPrice,
    edifact,
    edifactPrice,
    InteGreatMgrPrice
}) => {
    const [orderSource, setOrderSource] = useState([]);
    const [transport, setTransport] = useState([]);
    const [loading, setLoading] = useState(false);
    const [matchedObjects, setMatchedObjects] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [basisSystem, setBasisSystem] = useState([]);

    const allData = [...basisSystem, ...orderSource, ...transport];



    useEffect(() => {
        const fetchOrderSource = async (type) => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `https://engine.netsupport.dk:7080/website/v1/webpricing?prodgroup=rackbeat&type=${type}&period=${period}`,
                );
                setOrderSource(response.data.pricing);
            } catch (error) {
                console.error("Error fetching order source:", error.message);
                throw error;
            }
            setLoading(false);
        };

        fetchOrderSource("shop");
    }, [period]);

    useEffect(() => {
        const fetchTransport = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `https://engine.netsupport.dk:7080/website/v1/webpricing?prodgroup=transport&type=transport&period=${period}`,
                );
                setTransport(response.data.pricing);
            } catch (error) {
                console.error("Error fetching order source:", error.message);
                throw error;
            }
            setLoading(false);
        };

        fetchTransport();
    }, [period]);

    useEffect(() => {
        const fetchBasisSystem = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `https://engine.netsupport.dk:7080/website/v1/webpricing?prodgroup=basic&type=eco&period=${period}`,
                );
                setBasisSystem(response.data.pricing);
            } catch (error) {
                console.error("Error fetching order source:", error.message);
                throw error;
            }
            setLoading(false);
        };

        fetchBasisSystem();
    }, [period]);

    const calculateTotalPrice = () => {
        let total = 0;
        selectedProducts.forEach((productName) => {
            const product = allData.find(
                (item) => item.product === productName,
            );
            if (product) {
                total += product.price;
            }
        });
        setTotalPrice(total);
        setPrice(total);
    };

    useEffect(() => {
        calculateTotalPrice();
    }, [selectedProducts, system]);

    useEffect(() => {
        setMatchedObjects(
            allData.filter(
                (obj) =>
                    selectedProducts.includes(obj.product) && obj.price !== 0,
            ),
        );
    }, [selectedProducts]);
    

    return (
        <div className={`bg-[#e0eced] dark:bg-[#272f44] py-4 px-5 rounded-md`}>
            <p className="font-semibold text-[#f29650]">{text}</p>
            <div className="flex gap-2 justify-between items-center pt-5 pb-3">
                <p className="font-semibold text-[#2D838E]">
                    <span className="font-extrabold">
                        {edifact
                            ? totalPrice + edifactPrice + InteGreatMgrPrice
                            : selectedProducts.length > 0
                                ? totalPrice + InteGreatMgrPrice
                                : 0}
                        ,-
                    </span>{" "}
                    per. måned
                </p>
                {matchedObjects.length !== 0 && (
                    <ul className="text-sm border-[#2D838E] border-[1px] rounded p-1 h-fit">
                        {matchedObjects.map((object, idx) => (
                            <li
                                key={idx}
                                className="flex justify-between gap-2"
                            >
                                <span className="capitalize">
                                    {object.product}
                                </span>
                                <span>{object.price}</span>
                            </li>
                        ))}
                        <li className="flex justify-between gap-2">
                            <span className="capitalize">InteGreat Mgr</span>
                            <span>{InteGreatMgrPrice}</span>
                        </li>
                        {edifact && (
                            <li className="flex justify-between gap-2">
                                <span className="capitalize">edifact/VANS</span>
                                <span>{edifactPrice}</span>
                            </li>
                        )}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default YearlyPriser;
