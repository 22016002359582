import axios from 'axios';
import React, { useEffect, useState } from 'react'

function FooterTable() {
    const [loading, setLoading] = useState(false);
    const [basissystem, setBasisSystem] = useState([])
    const [webshops, setWebshops] = useState([]);
    const [butikskæder, setButiksaeder] = useState([]);
    const [diverse, setDiverse] = useState([])
    const [fragt, setFragt] = useState([])

    useEffect(() => {
        const fetchBasisSystem = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    'https://engine.netsupport.dk:7080/website/v1/retrievenames?txtgroup=3'
                );
                setBasisSystem(response.data.pricing);

            } catch (error) {
                console.error("Error fetching order source:", error.message);
                throw error;
            }
            setLoading(false);
        };
        fetchBasisSystem();
    }, []);

    useEffect(() => {
        const fetchWebshops = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    'https://engine.netsupport.dk:7080/website/v1/retrievenames?txtgroup=2'
                );
                setWebshops(response.data.pricing);

            } catch (error) {
                console.error("Error fetching order source:", error.message);
                throw error;
            }
            setLoading(false);
        };
        fetchWebshops();
    }, []);
    useEffect(() => {
        const fetchButiksaeder = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    'https://engine.netsupport.dk:7080/website/v1/retrievenames?txtgroup=1'
                );
                setButiksaeder(response.data.pricing);

            } catch (error) {
                console.error("Error fetching order source:", error.message);
                throw error;
            }
            setLoading(false);
        };
        fetchButiksaeder();
    }, []);
    useEffect(() => {
        const fetchFragt = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    'https://engine.netsupport.dk:7080/website/v1/retrievenames?txtgroup=4'
                );
                setFragt(response.data.pricing);

            } catch (error) {
                console.error("Error fetching order source:", error.message);
                throw error;
            }
            setLoading(false);
        };
        fetchFragt();
    }, []);

    useEffect(() => {
        const fetchDiverse = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    'https://engine.netsupport.dk:7080/website/v1/retrievenames?txtgroup=5'
                );
                setDiverse(response.data.pricing);

            } catch (error) {
                console.error("Error fetching order source:", error.message);
                throw error;
            }
            setLoading(false);
        };
        fetchDiverse();
    }, []);


    return (
        <div className="mt-10 md:mt-12 px-2 mb-20 max-w-screen-xl mx-auto">
            <h2 className="mb-6 text-center text-xl lg:text-2xl font-bold leading-tight">Integreat Solutions</h2>
            <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-2 lg:gap-4">
                <div className="col-span-1 md:col-span-2 lg:col-span-1">
                    <div className="bg-[#f5f5f5] dark:bg-[#272f44] dark:text-gray-400 shadow-lg rounded-lg p-4">
                        <h3 className="text-xl font-semibold text-[#2D838E] mb-4">Basis System</h3>
                        {basissystem?.map((basis, idx) => (
                            <p key={idx} className="mb-1">
                                {basis.product}
                            </p>
                        ))}
                    </div>
                </div>
                <div className="col-span-1 md:col-span-2 lg:col-span-1">
                    <div className="bg-[#f5f5f5] dark:bg-[#272f44] dark:text-gray-400 shadow-lg rounded-lg p-4">
                        <h3 className="text-xl font-semibold text-[#2D838E] mb-4">Webshops</h3>
                        {webshops?.map((webshop, idx) => (
                            <p key={idx} className="mb-1">
                                {webshop.product}
                            </p>
                        ))}
                    </div>
                </div>
                <div className="col-span-1 md:col-span-2 lg:col-span-1">
                    <div className="bg-[#f5f5f5] dark:bg-[#272f44] dark:text-gray-400 shadow-lg rounded-lg p-4">
                        <h3 className="text-xl font-semibold text-[#2D838E] mb-4">Butikskæder</h3>
                        {butikskæder?.map((butikskæde, idx) => (
                            <p key={idx} className="mb-1">
                                {butikskæde.product}
                            </p>
                        ))}
                    </div>
                </div>
                <div className="col-span-1 md:col-span-1 lg:col-span-1">
                    <div className="bg-[#f5f5f5] dark:bg-[#272f44] dark:text-gray-400 shadow-lg rounded-lg p-4">
                        <h3 className="text-xl font-semibold text-[#2D838E] mb-4">Fragt</h3>
                        {fragt?.map((fragtValue, idx) => (
                            <p key={idx} className="mb-1">
                                {fragtValue.product}
                            </p>
                        ))}
                    </div>
                </div>
                <div className="col-span-1 md:col-span-1 lg:col-span-1">
                    <div className="bg-[#f5f5f5] dark:bg-[#272f44] dark:text-gray-400 shadow-lg rounded-lg p-4">
                        <h3 className="text-xl font-semibold text-[#2D838E] mb-4">Diverse</h3>
                        {diverse?.map((diverseValue, idx) => (
                            <p key={idx} className="mb-1">
                                {diverseValue.product}
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterTable