export const losninger = [
    {
        name: 'Business Central',
        url: '/business_central'
    },
    {
        name: 'Rackbeat',
        url: '/rackbeat'
    },
    {
        name: 'Economic',
        url: '/economic'
    },
    {
        name: 'Dinero',
        url: '/dinero'
    },
    {
        name: 'C5/Navision',
        url: '/navision'
    },
    {
        name: 'Business Central Kundeportal',
        url: '/business_central_kundeportal'
    },
    {
        name: 'Business Central Payments',
        url: '/business_central_payments'
    },
    {
        name: 'Economic Kundeportal',
        url: '/economic_kundeportal'
    },
    {
        name: 'Economic Payments',
        url: '/economic_payments'
    },
];

export const basisSystem = [
    {
        product: 'Business Central',
        price: 950
    },
    {
        product: 'Rackbeat',
        price: 250
    },
    {
        product: 'Economic',
        price: 475
    },
    {
        product: 'Navision/C5',
        price: 580
    },
    {
        product: 'Dinero',
        price: 470
    },
];

export const transport = [
    {
        product: 'GLS',
        price: 250
    },
    {
        product: 'BFT',
        price: 250
    },
    {
        product: 'DHL',
        price: 250
    },
    {
        product: 'Webshipper',
        price: 390
    },
    {
        product: 'Shipmondo',
        price: 390
    },
    {
        product: 'Consignor',
        price: 450
    },
    {
        product: 'TMC',
        price: 250
    },
    {
        product: 'Alpi',
        price: 250
    },
    {
        product: 'AutoBude',
        price: 250
    },
];