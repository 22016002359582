import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Imge01 from "../assets/kv-logo.png"
import Section8 from "./Section8";

const Section4 = () => {
  const [infoData, setInfoData] = useState([]);

  useEffect(() => {
    const fetchInfoText = async (article, app, DK) => {
      try {
        const response = await axios.get(
          `https://engine.netsupport.dk:7080/account/v1/getinfotext?article=${article}&app=${app}&Language=${DK}`
        );
        return response.data.memo;
      } catch (error) {
        console.error("Error fetching info text:", error.message);
        throw error;
      }
    };

    const parameterSets = [
      { article: "main.5.1", app: "WEB", DK: "DK" },
      { article: "main.5.2", app: "WEB", DK: "DK" },
      { article: "main.7.1", app: "WEB", DK: "DK" },
      { article: "main.7.2", app: "WEB", DK: "DK" },
    ];

    const fetchDataForAllParameters = async () => {
      const fetchPromises = parameterSets.map((params) => fetchInfoText(params.article, params.app, params.DK));
      const results = await Promise.all(fetchPromises);
      setInfoData(results);
    };

    fetchDataForAllParameters();
  }, []);

  return (
    <div className="text-[#2D838E] lg:px-4 py-14">
      <div className="bg-[#e0eced] dark:bg-[#272f44] mx-8 md:mx-14 lg:mx-auto p-10 lg:p-24">
        <div className="max-w-screen-xl mx-8 md:mx-14 lg:mx-auto">
          <h2 className="font-bold text-4xl lg:text-4xl">{infoData[0]}</h2>
          <p className="mt-10 mb-1 md:mb-6 font-bold">
            Få minutter efter, at din kunde har placeret en ordre i webshoppen, bliver ordren behandlet
          </p>

          <pre className="font-normal">{infoData[1]}</pre>
        </div>

        <Section8 />
      </div>

      <div className="mt-24 max-w-screen-xl mx-8 md:mx-14 lg:mx-auto">
        <h2 className="font-bold text-4xl lg:text-4xl">{infoData[2]}</h2>

        <div className="flex flex-col md:flex-row mt-16 items-center gap-8">
          <img className="flex-auto" src={Imge01} alt="" />
          <div className="flex-auto">
            <h4 className="font-bold mb-3">KV energi</h4>
            <pre className="mb-4">{infoData[3]}</pre>

            <p className="mt-7 mb-14">
              <Link to="" className="font-bold hover:underline text-[#f29650]">
                Gå til kundens side her
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
