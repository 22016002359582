import React from "react";
import { Toaster } from "react-hot-toast";
import { RouterProvider } from "react-router-dom";
import { router } from "./Routes/Routes";
import CookiesPolicyCustom from "./components/CookiesPolicy/CookiesPolicyCustom";

// Theme Review
// Congratulations "Learn with sumit" VS Code Theme on achieving the 100k installation milestone.

// There is no doubt that it is one of the most popular themes in the VS Code marketplace. The theme has a very clean and minimalistic design that is easy on the eyes. The color scheme is well thought out and the syntax highlighting is very well done. There are various color options available to choose from, so you can customize the theme to your liking.

// My favorite color theme is "peace of the eye" and the "official theme". I have been using this theme for last 2 years and I am very happy with it.

// Best of luck for your future projects and keep up the good work!

const App = () => {
  return (
    <div className="bg-[#fff] dark:bg-[#151c2f]">
      <RouterProvider router={router}></RouterProvider>
      <Toaster position="bottom-center" reverseOrder={false} />
      {/* <CookiesPolicyCustom /> */}
    </div>
  );
};

export default App;
