import axios from "axios";
import React, { useEffect, useState } from "react";
import image5 from "../assets/Home05.png"

const Section8 = () => {
  const [infoData, setInfoData] = useState([]);

  useEffect(() => {
    const fetchInfoText = async (article, app, DK) => {
      try {
        const response = await axios.get(
          `https://engine.netsupport.dk:7080/account/v1/getinfotext?article=${article}&app=${app}&Language=${DK}`
        );
        return response.data.memo;
      } catch (error) {
        console.error("Error fetching info text:", error.message);
        throw error;
      }
    };

    const parameterSets = [
      { article: "main.6.1", app: "WEB", DK: "DK" },
      { article: "main.6.2", app: "WEB", DK: "DK" },
    ];

    const fetchDataForAllParameters = async () => {
      const fetchPromises = parameterSets.map((params) => fetchInfoText(params.article, params.app, params.DK));
      const results = await Promise.all(fetchPromises);
      setInfoData(results);
    };

    fetchDataForAllParameters();
  }, []);

  return (
    <div className="text-[#2D838E] w-full rounded-md mx-8 md:mx-14 lg:mx-auto p-10 lg:pb-0">
      <div className="hero text-[#2D838E]">
        <div className="hero-content flex-col lg:flex-row gap-14 items-center">
          <img
            src={image5}
            className="w-2/5 hidden lg:block"
            alt=""
          />
          <div className="flex-auto">
            <h1 className="text-4xl lg:text-4xl font-bold leading-tight mb-7">{infoData[0]}</h1>
            <div className="pl-0" dangerouslySetInnerHTML={{ __html: infoData[1] }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section8;
