import { useState } from "react";
// import { DarkModeSwitch } from "react-toggle-dark-mode";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import useDarkSide from "./hooks/useDarkSide";

export default function Switcher() {
  const [colorTheme, setTheme] = useDarkSide();
  const [darkSide, setDarkSide] = useState(colorTheme === "light" ? true : false);

  const toggleDarkMode = (checked) => {
    setTheme(colorTheme);
    setDarkSide(!darkSide);
  };

  return (
    <div>
      {darkSide && (
        <div className="cursor-pointer text-2xl text-white" onClick={toggleDarkMode}>
          <MdLightMode />
        </div>
      )}
      {!darkSide && (
        <div className="cursor-pointer text-2xl text-gray-600" onClick={toggleDarkMode}>
          <MdDarkMode />
        </div>
      )}
      {/* <DarkModeSwitch className="w-6 md:w-7 h-6 md:h-7" checked={!darkSide} onChange={toggleDarkMode} size={30} /> */}
    </div>
  );
}
