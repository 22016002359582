import axios from "axios";
import React, { useEffect, useState } from "react";
import About01 from "../assets/About01.png";
import About02 from "../assets/About02.png";
import About03 from "../assets/About03.png"

const OmOs = () => {
  const [infoData, setInfoData] = useState([]);

  useEffect(() => {
    const fetchInfoText = async (article, app, DK) => {
      try {
        const response = await axios.get(
          `https://engine.netsupport.dk:7080/account/v1/getinfotext?article=${article}&app=${app}&Language=${DK}`
        );
        return response.data.memo;
      } catch (error) {
        console.error("Error fetching info text:", error.message);
        throw error;
      }
    };

    const parameterSets = [
      { article: "about.1.1", app: "WEB", DK: "DK" },
      { article: "about.1.2", app: "WEB", DK: "DK" },
      { article: "about.2.1", app: "WEB", DK: "DK" },
      { article: "about.2.2", app: "WEB", DK: "DK" },
      { article: "about.3.1", app: "WEB", DK: "DK" },
      { article: "about.3.2", app: "WEB", DK: "DK" },
      { article: "about.4.1", app: "WEB", DK: "DK" },
      { article: "about.4.2", app: "WEB", DK: "DK" },
    ];

    const fetchDataForAllParameters = async () => {
      const fetchPromises = parameterSets.map((params) => fetchInfoText(params.article, params.app, params.DK));
      const results = await Promise.all(fetchPromises);
      setInfoData(results);
    };

    fetchDataForAllParameters();
  }, []);

  return (
    <div className="text-[#2D838E] pt-24 md:pt-28 lg:pt-32">
      {/* first part */}
      <div className="mb-4 lg:mb-0">
        <div className="hero hidden lg:block max-w-screen-xl mx-auto">
          <div className="hero-content pb-0 flex-col justify-between lg:flex-row-reverse">
            <img src={About01} className="flex-auto max-w-[30%]" alt="" />
            <div className="flex-auto max-w-[55%]">
              <h1 className="text-4xl font-bold leading-tight">{infoData[0]}</h1>
              <pre className="my-5 pl-4 border-l-[3px] border-l-amber-600 whitespace-pre-wrap">{infoData[1]}</pre>
            </div>
          </div>
        </div>

        <div className="hero lg:hidden max-w-screen-xl mx-auto">
          <div className="hero-content flex-col lg:flex-row-reverse">
            <h1 className="text-4xl text-center font-bold leading-tight">{infoData[0]}</h1>
            <div className="flex-auto flex items-center flex-col">
              <img src={About01} className="max-w-[70%] md:max-w-[50%]" alt="" />
              <pre className="mt-8 mb-4 py-1">{infoData[1]}</pre>
            </div>
          </div>
        </div>
      </div>

      {/* second part */}
      <div className="bg-[#e0eced] dark:bg-[#272f44] rounded-md my-12 lg:my-20 p-6 md:p-10">
        <div className="hero hidden lg:block max-w-screen-xl mx-4 md:mx-14 lg:mx-auto">
          <div className="hero-content pb-0 flex-col justify-between lg:flex-row">
            <img src={About02} className="flex-auto max-w-[45%]" alt="" />
            <div className="flex-auto max-w-[60%]">
              <h1 className="text-4xl font-bold leading-tight">{infoData[2]}</h1>
              <pre className="my-5 pl-4 border-l-[3px] border-l-amber-600">{infoData[3]}</pre>
            </div>
          </div>
        </div>

        <div className="hero lg:hidden">
          <div className="hero-content flex-col lg:flex-row-reverse">
            <h1 className="text-2xl text-center font-bold leading-tight">{infoData[2]}</h1>
            <div className="flex-auto flex items-center flex-col">
              <img src={About02} className="w-full max-w-[90%] md:max-w-[70%]" alt="" />
              <pre className="mt-8 mb-4 py-1">{infoData[3]}</pre>
            </div>
          </div>
        </div>
      </div>

      {/* third part */}
      <div className="max-w-screen-xl rounded-md mx-4 md:mx-14 lg:mx-auto my-12 lg:my-10 p-10">
        <h1 className="text-4xl font-bold leading-tight">{infoData[4]}</h1>
        <pre className="my-5 border-l-[3px] border-l-amber-600 pl-6 whitespace-pre-wrap">{infoData[5]}</pre>
      </div>

      {/* contact part */}
      <div className="mb-10 lg:mb-16">
        <div className="hero hidden lg:block max-w-screen-xl mx-auto">
          <div className="hero-content pb-0 flex-col justify-between lg:flex-row-reverse">
            <img
              src={About03}
              className="flex-auto max-w-[30%]"
              alt=""
            />
            <div className="flex-auto max-w-[55%]">
              <h1 className="text-4xl font-bold leading-tight">{infoData[6]}</h1>
              <pre className="my-5 pl-6 border-l-[3px] border-l-amber-600 whitespace-pre-wrap">{infoData[7]}</pre>
            </div>
          </div>
        </div>

        <div className="hero lg:hidden">
          <div className="hero-content flex-col lg:flex-row-reverse">
            <h1 className="text-2xl text-center font-bold leading-tight">{infoData[6]}</h1>
            <div className="flex-auto flex items-center flex-col">
              <img
                src={About03}
                className="max-w-[80%] md:max-w-[50%] mt-2"
                alt=""
              />
              <pre className="mt-8 mb-4 py-1">{infoData[7]}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OmOs;
