/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import YearlyPriser from "./Priser/YearlyPriser";

const PriserDetails = () => {
  const [system, setSystem] = useState("");
  const [period, setPeriod] = useState("12");
  const [orderSource, setOrderSource] = useState([]);
  const [transport, setTransport] = useState([]);
  const [basisSystem, setBasisSystem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [infoData, setInfoData] = useState([]);
  const [edifact, setEdifact] = useState(false);
  const [price, setPrice] = useState(0);

  const [selectedOption, setSelectedOption] = useState(false);

  const allData = [...basisSystem, ...orderSource, ...transport];

  // fetching v2 version

  const [newData, setNewData] = useState([]);
  const filteredData = newData?.filter(p => p.type === "bcprods")
  // console.log(filteredData)
  // console.log(newData)
  useEffect(() => {
    fetch(`https://engine.netsupport.dk:7080/website/v2/webpricing`)
      .then(response => response.json())
      .then(data => setNewData(data.pricing))
      .catch(error => console.error(error));
  }, []);

  const Edifact = newData?.find(p => p.product === 'edifact')
  const EdifactYearPrice = Edifact?.yearPrice;
  const EdifactSemPrice = Edifact?.semesterPrice;
  const EdifactMonthPrice = Edifact?.monthPrice;

  const InteGreatMgr = newData?.find(p => p.product === 'InteGreat Mgr')
  const InteGreatMgrYearPrice = InteGreatMgr?.yearPrice;
  const InteGreatMgrSemPrice = InteGreatMgr?.semesterPrice;
  const InteGreatMgrMontPrice = InteGreatMgr?.monthPrice;

  //console.log(newData)
  // const existData = newData?.filter(p=>p.displayGroup!==null)
  // console.log(existData)

  // fetching contents
  useEffect(() => {
    const fetchInfoText = async (article, app, DK) => {
      try {
        const response = await axios.get(
          `https://engine.netsupport.dk:7080/account/v1/getinfotext?article=${article}&app=${app}&Language=${DK}`
        );
        return response.data.memo;
      } catch (error) {
        console.error("Error fetching info text:", error.message);
        throw error;
      }
    };

    const parameterSets = [
      { article: "pris.1.1", app: "WEB", DK: "DK" },
      { article: "pris.1.2", app: "WEB", DK: "DK" },
      { article: "pris.1.3", app: "WEB", DK: "DK" },
      { article: "pris.2.1", app: "WEB", DK: "DK" },
      { article: "pris.2.2", app: "WEB", DK: "DK" },
    ];

    const fetchDataForAllParameters = async () => {
      const fetchPromises = parameterSets.map((params) => fetchInfoText(params.article, params.app, params.DK));
      const results = await Promise.all(fetchPromises);
      setInfoData(results);
    };

    fetchDataForAllParameters();
  }, []);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedProducts([...selectedProducts, value]);
      setSelectedOption(true)
    } else {
      setSelectedProducts(selectedProducts.filter((product) => product !== value));
      setSelectedOption(false)
    }
  };

  // fetching order sources
  useEffect(() => {
    const fetchOrderSource = async (type) => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://engine.netsupport.dk:7080/website/v1/webpricing?prodgroup=rackbeat&type=${type}&period=${period}`
        );
        setOrderSource(response.data.pricing);
        setSelectedProducts([]);
        setPeriod(period);
      } catch (error) {
        console.error("Error fetching order source:", error.message);
        throw error;
      }
      setLoading(false);
    };

    fetchOrderSource("shop");
  }, []);

  // fetching transport
  useEffect(() => {
    const fetchTransport = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://engine.netsupport.dk:7080/website/v1/webpricing?prodgroup=transport&type=transport&period=${period}`
        );
        setTransport(response.data.pricing);
      } catch (error) {
        console.error("Error fetching order source:", error.message);
        throw error;
      }
      setLoading(false);
    };

    fetchTransport();
  }, []);

  // fetching basis system
  useEffect(() => {
    const fetchBasisSystem = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://engine.netsupport.dk:7080/website/v1/webpricing?prodgroup=basic&type=eco&period=${period}`
        );
        setBasisSystem(response.data.pricing);
      } catch (error) {
        console.error("Error fetching order source:", error.message);
        throw error;
      }
      setLoading(false);
    };

    fetchBasisSystem();
  }, [period]);

  useEffect(() => {
    setEdifact(false);
    selectedProducts.forEach((productName) => {
      const product = allData.find((item) => item.product === productName);
      if (product) {
        if (product.edifact === "Y") {
          setEdifact(true);
        }
      }
    });
  }, [selectedProducts]);

  return (
    <div className="max-w-screen-xl mx-auto pt-24 lg:pt-32 mb-8 px-4 text-lg text-[#2D838E]">
      <div className="mb-2 space-y-3 text-base">
        <h2 className="text-4xl font-bold leading-tight">{infoData[0]}</h2>
        <p>
          Priserne er afhængigt af de moduler som du åbner for, du betaler kun for de moduler som du bruger. Du kan
          altid opsige abonnementet ved udgangen til en faktureringsperiode.
        </p>
        <pre>{infoData[1]}</pre>
        <div className="flex items-center gap-1 mt-4">
          <p><BsInfoCircle /></p>
          <pre> Alle priser er i DKK, eksklusiv moms, og eksklusiv konfiguration og test</pre>
        </div>
        {/* <pre>{infoData[2]}</pre> */}

        <div className="pt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
            <YearlyPriser
              text="Ved fakturering årligt forud"
              selectedProducts={selectedProducts}
              system={system}
              period={12}
              edifact={edifact}
              edifactPrice={EdifactYearPrice}
              InteGreatMgrPrice={InteGreatMgrYearPrice}
              setPrice={setPrice}

            />
            <YearlyPriser
              text="Ved fakturering halvårligt forud"
              selectedProducts={selectedProducts}
              system={system}
              period={6}
              edifact={edifact}
              edifactPrice={EdifactSemPrice}
              InteGreatMgrPrice={InteGreatMgrSemPrice}
              setPrice={setPrice}
            />
            <YearlyPriser
              text="Ved månedlig fakturering"
              selectedProducts={selectedProducts}
              system={system}
              period={1}
              edifact={edifact}
              edifactPrice={EdifactMonthPrice}
              InteGreatMgrPrice={InteGreatMgrMontPrice}
              setPrice={setPrice}
            />
          </div>
        </div>
      </div>

      <div className="text-base">
        <p className="font-semibold text-[#2D838E] bg-[#e0eced] dark:bg-[#272f44] rounded p-6">
          Automatisk ordrehåndtering (24*7) fra &nbsp;&nbsp;
          <span className="text-[40px] font-black">
            {edifact && InteGreatMgr && selectedOption
              ? (((price + 290 + 350) * 12) / 365).toFixed()
              : selectedOption && InteGreatMgr ? (((price + 350) * 12) / 365).toFixed() : ((price * 12) / 365).toFixed()}
            ,-
          </span>
          &nbsp;&nbsp; per dag.
        </p>

        {edifact && (
          <p className="italic mt-2">
            <span className="font-medium">{infoData[3]}:</span> {infoData[4]}
          </p>
        )}
      </div>

      <div className="mt-6 w-full flex flex-col md:flex-row gap-4 md:gap-10 lg:gap-16 xl:gap-20 justify-between">
        {orderSource.length > 0 && (
          <div className="flex-1">
            <h3 className="text-[#2D838E] font-semibold h-[84px]">
              Vælg webshops og butikker du <br /> forventer at modtage ordrer fra <br /> Ordrer hentes fra:
            </h3>
            <div className="mt-2 space-y-1 w-[225px]">
              {
                <div className="space-y-1">
                  {orderSource.map((item, idx) => (
                    <div key={idx} className="flex gap-2">
                      <input
                        className="cursor-pointer"
                        type="checkbox"
                        name="order-source"
                        value={`${item.product}`}
                        id={`${item.product}`}
                        checked={selectedProducts.includes(item.product)}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="flex w-full justify-between capitalize cursor-pointer"
                        htmlFor={`${item.product}`}
                      >
                        <p>{item.product}</p>
                        <p>{item.price}</p>
                      </label>
                    </div>
                  ))}
                  {edifact && (
                    <div className="flex gap-2 w-full pt-3">
                      <label className="flex w-full justify-between capitalize cursor-pointer">
                        <div className="flex items-center gap-2">
                          <input type="checkbox" checked />
                          <p>edifact/VANS</p>
                        </div>
                        <p>{EdifactYearPrice}</p>
                      </label>
                    </div>
                  )}
                </div>
              }
            </div>
          </div>
        )}

        <div className="flex-1">
          <h3 className="font-semibold text-[#2D838E] h-[84px]">
            Vælg ordrebehandling system <br /> Ordrer sendes til:
          </h3>
          <div className="mt-2 space-y-1 w-[225px]">
            {basisSystem.map((item, idx) => (
              <div key={idx} className="flex gap-2 w-full">
                <input
                  className="cursor-pointer"
                  type="checkbox"
                  name="system"
                  value={`${item.product}`}
                  id={`${item.product}`}
                  checked={selectedProducts.includes(item.product)}
                  onChange={handleCheckboxChange}
                />
                <label className="flex w-full justify-between capitalize cursor-pointer" htmlFor={`${item.product}`}>
                  <p>{item.product}</p>
                  <p>{item.price}</p>
                </label>
              </div>
            ))}
            <div className="flex gap-2 w-full">
              <label className="flex w-full justify-between capitalize cursor-pointer">
                <div className="flex items-center gap-2">
                  <input type="checkbox" checked={selectedProducts.length > 0} />
                  <p>InteGreat Mgr</p>
                </div>
                <p>{InteGreatMgrYearPrice}</p>
              </label>
            </div>
          </div>
        </div>

        {orderSource.length > 0 && (
          <div className="flex-1">
            <h3 className="text-[#2D838E] font-semibold h-[84px]">
              Vælg ordrebehandling system <br /> Transport/Lager:
            </h3>
            <div className="mt-2 space-y-1 w-[225px]">
              {
                <div className="space-y-1">
                  {transport.map((item, idx) => (
                    <div key={idx} className="flex gap-2">
                      <input
                        className="cursor-pointer"
                        type="checkbox"
                        name="transport"
                        value={`${item.product}`}
                        id={`${item.product}`}
                        checked={selectedProducts.includes(item.product)}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="flex w-full justify-between capitalize cursor-pointer"
                        htmlFor={`${item.product}`}
                      >
                        <p>{item.product}</p>
                        <p>{item.price}</p>
                      </label>
                    </div>
                  ))}
                </div>
              }
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center gap-1 mt-6 text-base">
        <p><BsInfoCircle /></p>
        <pre> Alle priser er i DKK, eksklusiv moms, og eksklusiv konfiguration og test</pre>
      </div>

      <div className="mt-40 mb-20">
        <h2 className="text-4xl font-bold leading-tight my-6">Business Central Addons : bcReminder og bcPortal</h2>
        <div className="overflow-x-auto">
          <table className="table table-xs text-lg border-none rounded-lg overflow-hidden">
            <thead className=" text-[#f29650] bg-[#e0eced] dark:bg-[#272f44] p-4 border-none">
              <tr className=" rounded-lg overflow-hidden">
                <th className="p-4 font-semibold text-base">Produkt</th>
                <th className="p-4 font-semibold text-base text-center"> Ved fakturering årligt forud</th>
                <th className="p-4 font-semibold text-base text-center">Ved fakturering halvårligt forud</th>
                <th className="p-4 font-semibold text-base text-center">Ved månedlig fakturering</th>
              </tr>
            </thead>
            <tbody className="text-lg text-[#2D838E]">
              {
                filteredData?.map((item, idx) => <tr key={idx} className="text-lg text-[#2D838E] border-none">
                  <td className="px-4 font-semibold text-base text-[#2D838E]">{item.product}</td>
                  <td className="px-4 font-semibold text-base text-[#2D838E] text-center">{item?.yearPrice}</td>
                  <td className="px-4 font-semibold text-base text-[#2D838E] text-center">{item?.semesterPrice}</td>
                  <td className="px-4 font-semibold text-base text-[#2D838E] text-center">{item?.monthPrice}</td>
                </tr>
                )}
            </tbody>
          </table>
          <div className="flex items-center gap-1 mt-4 text-base">
            <p><BsInfoCircle /></p>
            <pre> Alle priser er i DKK, eksklusiv moms, og eksklusiv konfiguration og test</pre>
          </div>
        </div>

      </div>
    </div>
  );
};

export default PriserDetails;
