import axios from "axios";
import React, { useEffect, useState } from "react";
import RackbeatLogo from "../assets/RackbeatLogo.png";
import DanDomainLogo from "../assets/DandomainLogo.png";
import EconomicLogo from "../assets/EconomicLogo.png";
import DineroLogo from "../assets/DineroLogo.png";
import KodeEndLogo from "../assets/KodeEndLogo.png";

const Partners = () => {
  const [infoData, setInfoData] = useState([]);

  useEffect(() => {
    const fetchInfoText = async (article, app, DK) => {
      try {
        const response = await axios.get(
          `https://engine.netsupport.dk:7080/account/v1/getinfotext?article=${article}&app=${app}&Language=${DK}`
        );
        return response.data.memo;
      } catch (error) {
        console.error("Error fetching info text:", error.message);
        throw error;
      }
    };

    const parameterSets = [
      { article: "part.1.1", app: "WEB", DK: "DK" },
      { article: "part.1.2", app: "WEB", DK: "DK" },
      { article: "part.1.3", app: "WEB", DK: "DK" },
      { article: "part.2.1", app: "WEB", DK: "DK" },
      { article: "part.2.2", app: "WEB", DK: "DK" },
      { article: "part.3.1", app: "WEB", DK: "DK" },
      { article: "part.3.2", app: "WEB", DK: "DK" },
      { article: "part.4.1", app: "WEB", DK: "DK" },
      { article: "part.4.2", app: "WEB", DK: "DK" },
      { article: "part.5.1", app: "WEB", DK: "DK" },
      { article: "part.5.2", app: "WEB", DK: "DK" },
    ];

    const fetchDataForAllParameters = async () => {
      const fetchPromises = parameterSets.map((params) => fetchInfoText(params.article, params.app, params.DK));
      const results = await Promise.all(fetchPromises);
      setInfoData(results);
    };

    fetchDataForAllParameters();
  }, []);

  return (
    <div className="text-[#2D838E] pt-24 md:pt-28 lg:pt-32">
      <h2 className="text-2xl md:text-4xl uppercase text-center font-black">Partners</h2>
      {/* rackbeat part */}
      <div className="py-8 lg:py-16">
        <div className="hero hidden lg:block max-w-screen-xl mx-auto">
          <div className="hero-content pb-0 flex-col justify-between lg:flex-row">
            <img
              src={RackbeatLogo}
              className="flex-auto max-w-[30%]"
              style={{
                filter:
                  "drop-shadow(2px 0 0 white) drop-shadow(0 2px 0 white)drop-shadow(-2px 0 0 white) drop-shadow(0 -2px 0 white)",
              }}
              alt=""
            />
            <div className="flex-auto max-w-[60%]">
              <h1 className="text-4xl font-bold leading-tight">{infoData[0]}</h1>
              <pre className="my-5 pl-4 border-l-[3px] border-l-amber-600">{infoData[1]}</pre>
              <pre className="my-5 pl-4 border-l-[3px] border-l-amber-600">{infoData[2]}</pre>
            </div>
          </div>
        </div>

        <div className="hero lg:hidden">
          <div className="hero-content flex-col lg:flex-row-reverse mx-6">
            <h1 className="text-4xl font-bold text-center leading-tight">{infoData[0]}</h1>
            <div className="flex-auto flex items-center flex-col">
              <img
                src={RackbeatLogo}
                className="w-full max-w-[70%] md:max-w-[50%]"
                style={{
                  filter:
                    "drop-shadow(2px 0 0 white) drop-shadow(0 2px 0 white)drop-shadow(-2px 0 0 white) drop-shadow(0 -2px 0 white)",
                }}
                alt=""
              />
              <pre className="mt-8 mb-4 py-1">{infoData[1]}</pre>
              <pre className="mb-8 py-1">{infoData[2]}</pre>
            </div>
          </div>
        </div>
      </div>

      {/* dandomain part */}
      <div className="bg-[#e0eced] dark:bg-[#272f44] py-8 lg:py-16">
        <div className="hero hidden lg:block max-w-screen-xl mx-auto">
          <div className="hero-content pb-0 flex-col justify-between lg:flex-row">
            <img src={DanDomainLogo} className="flex-auto max-w-[30%]" alt="rackbeat" />
            <div className="flex-auto max-w-[60%]">
              <h1 className="text-4xl font-bold leading-tight text-[#2D838E]">{infoData[3]}</h1>
              <pre className="my-5 pl-4 border-l-[3px] border-l-amber-600 text-[#2D838E]">{infoData[4]}</pre>
            </div>
          </div>
        </div>

        <div className="hero lg:hidden">
          <div className="hero-content flex-col lg:flex-row-reverse mx-6">
            <h1 className="text-4xl font-bold text-center leading-tight">{infoData[3]}</h1>
            <div className="flex-auto flex items-center flex-col">
              <img src={DanDomainLogo} className="w-full max-w-[70%] md:max-w-[50%]" alt="dandomain" />
              <pre className="mt-8 mb-4 py-1">{infoData[4]}</pre>
            </div>
          </div>
        </div>
      </div>

      {/* economic part */}
      <div className="py-8 lg:py-16">
        <div className="hero hidden lg:block max-w-screen-xl mx-auto">
          <div className="hero-content pb-0 flex-col justify-between lg:flex-row">
            <img
              src={EconomicLogo}
              className="flex-auto max-w-[30%]"
              style={{
                filter:
                  "drop-shadow(2px 0 0 white) drop-shadow(0 2px 0 white)drop-shadow(-2px 0 0 white) drop-shadow(0 -2px 0 white)",
              }}
              alt=""
            />
            <div className="flex-auto max-w-[60%]">
              <h1 className="text-4xl font-bold leading-tight text-[#2D838E]">{infoData[5]}</h1>
              <pre className="my-5 pl-4 border-l-[3px] border-l-amber-600 text-[#2D838E]">{infoData[6]}</pre>
            </div>
          </div>
        </div>

        <div className="hero lg:hidden">
          <div className="hero-content flex-col lg:flex-row-reverse mx-6">
            <h1 className="text-4xl text-center font-bold leading-tight">{infoData[5]}</h1>
            <div className="flex-auto flex items-center flex-col">
              <img
                src={EconomicLogo}
                className="w-full max-w-[70%] md:max-w-[50%]"
                style={{
                  filter:
                    "drop-shadow(2px 0 0 white) drop-shadow(0 2px 0 white)drop-shadow(-2px 0 0 white) drop-shadow(0 -2px 0 white)",
                }}
                alt=""
              />
              <pre className="mt-8 mb-4 py-1">{infoData[6]}</pre>
            </div>
          </div>
        </div>
      </div>

      {/* dinero part */}
      <div className="bg-[#e0eced] dark:bg-[#272f44] py-8 lg:py-16">
        <div className="hero hidden lg:block max-w-screen-xl mx-auto">
          <div className="hero-content pb-0 flex-col justify-between lg:flex-row">
            <img src={DineroLogo} className="flex-auto max-w-[30%]" alt="rackbeat" />
            <div className="flex-auto max-w-[60%]">
              <h1 className="text-4xl font-bold leading-tight text-[#2D838E]">{infoData[7]}</h1>
              <pre className="my-5 pl-4 border-l-[3px] border-l-amber-600 text-[#2D838E]">{infoData[8]}</pre>
            </div>
          </div>
        </div>

        <div className="hero lg:hidden">
          <div className="hero-content flex-col lg:flex-row-reverse mx-6">
            <h1 className="text-4xl text-center font-bold leading-tight">{infoData[7]}</h1>
            <div className="flex-auto flex items-center flex-col">
              <img src={DineroLogo} className="w-full max-w-[70%] md:max-w-[50%]" alt="dandomain" />
              <pre className="mt-8 mb-4 py-1">{infoData[8]}</pre>
            </div>
          </div>
        </div>
      </div>

      {/* kodeend part */}
      <div className="py-8 lg:py-16">
        <div className="hero hidden lg:block max-w-screen-xl mx-auto">
          <div className="hero-content pb-0 flex-col justify-between lg:flex-row">
            <img
              src={KodeEndLogo}
              className="flex-auto max-w-[30%]"
              style={{
                filter:
                  "drop-shadow(1px 0 0 #151c2f) drop-shadow(0 1px 0 #151c2f) drop-shadow(-1px 0 0 #151c2f) drop-shadow(0 -1px 0 #151c2f)",
              }}
              alt=""
            />
            <div className="flex-auto max-w-[60%]">
              <h1 className="text-4xl font-bold leading-tight text-[#2D838E]">{infoData[9]}</h1>
              <pre className="my-5 pl-4 border-l-[3px] border-l-amber-600 text-[#2D838E]">{infoData[10]}</pre>
            </div>
          </div>
        </div>

        <div className="hero lg:hidden">
          <div className="hero-content flex-col lg:flex-row-reverse mx-6">
            <h1 className="text-4xl text-center font-bold leading-tight">{infoData[9]}</h1>
            <div className="flex-auto flex items-center flex-col">
              <img
                src={KodeEndLogo}
                className="w-full max-w-[70%] md:max-w-[50%]"
                style={{
                  filter:
                    "drop-shadow(1px 0 0 #151c2f) drop-shadow(0 1px 0 #151c2f) drop-shadow(-1px 0 0 #151c2f) drop-shadow(0 -1px 0 #151c2f)",
                }}
                alt=""
              />
              <pre className="mt-8 mb-4 py-1">{infoData[10]}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
