import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import image03 from '../assets/Home003.png'
import img04 from "../assets/Home04.png";

const Section3 = () => {
  const [infoData, setInfoData] = useState([]);

  useEffect(() => {
    const fetchInfoText = async (article, app, DK) => {
      try {
        const response = await axios.get(
          `https://engine.netsupport.dk:7080/account/v1/getinfotext?article=${article}&app=${app}&Language=${DK}`
        );
        return response.data.memo;
      } catch (error) {
        console.error("Error fetching info text:", error.message);
        throw error;
      }
    };

    const parameterSets = [
      { article: "main.4.1", app: "WEB", DK: "DK" },
      { article: "main.4.2", app: "WEB", DK: "DK" },
      { article: "main.4.3", app: "WEB", DK: "DK" },
      { article: "main.4.4", app: "WEB", DK: "DK" },
    ];

    const fetchDataForAllParameters = async () => {
      const fetchPromises = parameterSets.map((params) => fetchInfoText(params.article, params.app, params.DK));
      const results = await Promise.all(fetchPromises);
      setInfoData(results);
    };

    fetchDataForAllParameters();
  }, []);

  return (
    <div className="">
      <div className="bg-[#e0eced] dark:bg-[#272f44] px-3 py-8 lg:py-28 text-[#2D838E]">
        <div className="hero text max-w-screen-xl mx-auto">
          <div className="hero-content flex-col lg:flex-row lg:gap-28">
            <img
              src={image03}
              className="flex-1 max-w-md lg:max-w-sm w-full"
              alt=""
            />
            <div className="flex-1">
              <h1 className="text-4xl lg:text-4xl font-bold text-[#2D838E] leading-tight">{infoData[0]}</h1>
              <div className="mt-4" dangerouslySetInnerHTML={{ __html: infoData[1] }} />
            </div>
          </div>
        </div>
      </div>

      <div className="px-3 pt-8 mt-8 lg:mt-20">
        <div className="max-w-screen-xl mx-auto text-[#2D838E]">
          <div className="hero text">
            <div className="hero-content flex-col lg:flex-row lg:gap-20">
              <img
                src={img04}
                className="flex-1 lg:max-w-md w-full"
                alt=""
              />
              <div className="flex-1">
                <h1 className="text-4xl lg:text-4xl font-bold text-[#2D838E] leading-tight">{infoData[2]}</h1>
                <pre className="py-6 lg:pr-16 text-[#2D838E] leading-normal lg:leading-loose">{infoData[3]}</pre>
              </div>
            </div>
          </div>
        </div>

        {/* section footer */}
        <div className="max-w-screen-xl mx-auto">
          <hr className="h-1 border-cyan-600 mt-10" />

          <h3 className="mt-14 text-center font-bold text-[#2D838E] mb-8">
            InteGreat er fleksibel og kan tilpasses, samt udvides, til netop din forretning
          </h3>

          {/* <Link to="/kontakt">
            <h3 className="mb-9 lg:mb-28 mt-6 lg:mt-14 font-bold text-[#f29650] hover:underline inline-block">
              Kontakt os her, for at høre mere
            </h3>
          </Link> */}
          <Link
            to="/kontakt"
            className="btn btn-primary rounded-full bg-[#f29650] text-white border-[#f29650] border-2 uppercase px-10 hover:bg-[#ffa954] hover:border-[#f29650]"
          >
            Kontakt os
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Section3;
