import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Img01 from '../assets/EconomicKundeportal01.png'
import Img02 from "../assets/EconomicKundeportal02.png";

const EconomicKundeportalTable = () => {
  const [infoData, setInfoData] = useState([]);

  useEffect(() => {
    const fetchInfoText = async (article, app, DK) => {
      try {
        const response = await axios.get(
          `https://engine.netsupport.dk:7080/account/v1/getinfotext?article=${article}&app=${app}&Language=${DK}`
        );
        return response.data.memo;
      } catch (error) {
        console.error("Error fetching info text:", error.message);
        throw error;
      }
    };

    const parameterSets = [
      { article: "portal.1.1", app: "WEB", DK: "DK" },
      { article: "portal.1.2", app: "WEB", DK: "DK" },
      { article: "portal.2.1", app: "WEB", DK: "DK" },
      { article: "portal.2.2", app: "WEB", DK: "DK" },
      { article: "portal.3.1", app: "WEB", DK: "DK" },
      { article: "portal.3.2", app: "WEB", DK: "DK" },
      { article: "portal.3.3", app: "WEB", DK: "DK" },
      { article: "portal.3.4", app: "WEB", DK: "DK" },
      { article: "portal.3.5", app: "WEB", DK: "DK" },
    ];

    const fetchDataForAllParameters = async () => {
      const fetchPromises = parameterSets.map((params) => fetchInfoText(params.article, params.app, params.DK));
      const results = await Promise.all(fetchPromises);
      setInfoData(results);
    };

    fetchDataForAllParameters();
  }, []);

  return (
    <div className="text-[#2D838E] pt-20 md:pt-28 lg:pt-36">
      {/* hero part */}
      <div className="mb-4 lg:mb-0">
        <div className="hero hidden lg:block max-w-screen-xl mx-auto mb-4">
          <div className="hero-content pb-0 flex-col justify-between lg:flex-row-reverse">
            <img
              src={Img01}
              className="flex-auto max-w-[50%]"
              alt=""
            />
            <div className="flex-auto max-w-[40%]">
              <h1 className="text-4xl font-bold leading-tight">{infoData[0]}</h1>
              <pre className="my-8 py-1 pl-4 border-l-[3px] border-l-amber-600">{infoData[1]}</pre>
            </div>
          </div>
        </div>

        <div className="hero lg:hidden max-w-screen-xl mx-auto">
          <div className="hero-content flex-col lg:flex-row-reverse">
            <h1 className="text-4xl text-center font-bold leading-tight">{infoData[0]}</h1>
            <div className="flex-auto flex items-center flex-col">
              <img
                src={Img01}
                className="max-w-[90%] md:max-w-[60%]"
                alt=""
              />
              <pre className="my-8 py-1">{infoData[1]}</pre>
            </div>
          </div>
        </div>
      </div>

      {/* second part */}
      <div className="bg-[#e0eced] dark:bg-[#272f44] py-12 mb-4">
        <div className="max-w-screen-xl mx-auto hero">
          <div className="hero-content flex-col lg:flex-row lg:gap-10">
            <img src={Img02} className="max-w-[100%] md:max-w-[65%] lg:max-w-[35%]" alt="" />
            <div className="max-w-[100%] lg:max-w-[65%] mt-3 lg:mt-0">
              <h1 className="text-4xl lg:text-4xl font-bold leading-tight">{infoData[2]}</h1>
              <pre className="py-3 lg:py-6">{infoData[3]}</pre>

              <div>
                <Link to="">
                  <h3 className="font-bold text-[#f29650] hover:underline">
                    Prøv e-conomic Kundeportal 30 dage gratis!
                  </h3>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* third part */}
      <div className="py-20 mb-2">
        <div className="max-w-screen-xl mx-auto">
          <div className="hero">
            <div className="hero-content flex-col lg:flex-row-reverse lg:gap-40">
              <div className="flex-1">
                <h1 className="text-4xl lg:text-4xl font-bold leading-tight">{infoData[4]}</h1>
                <pre className="py-4 lg:pr-16 font-bold">{infoData[5]}</pre>
                <h3 className="font-bold text-[#f29650] hover:underline inline-block">{infoData[6]}</h3>
                <pre className="pt-16 font-bold lg:pr-16">{infoData[7]}</pre>
                <pre className="py-4 lg:pr-16">{infoData[8]}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EconomicKundeportalTable;
