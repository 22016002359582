import axios from "axios";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-hot-toast";

const initialValue = {
  name: "",
  company: "",
  email: "",
  phone: "",
  remark: "",
  location: "",
};

const Contact = () => {
  const [formData, setFormData] = useState(initialValue);
  const [checked, setChecked] = useState(false);
  const [location, setLocation] = useState(null);
  const [verified, setVerified] = useState(false);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  const sendContactInfo = (data) => {
    const options = {
      method: "POST",
      url: "https://engine.netsupport.dk:7080/website/v1/customerrequest",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
      },
      data: data,
    };

    axios
      .request(options)
      .then(function (response) {
        toast.success(response.data.message);
        setFormData({
          name: "",
          company: "",
          email: "",
          phone: "",
          remark: "",
          location: "",
        });
        setChecked(false);
      })
      .catch(function (error) {
        console.error(error);
        setFormData({
          name: "",
          company: "",
          email: "",
          phone: "",
          remark: "",
          location: "",
        });
        setChecked(false);
      });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChecked = () => {
    setChecked(!checked);
  };

  const handleContact = (e) => {
    e.preventDefault();
    if (location?.latitude && location?.longitude) {
      formData.location = `lat=${location.latitude};lon=${location.longitude}`;
    }
    if (checked) {
      sendContactInfo(formData);
    } else {
      toast.error("Please the check the box!");
    }
  };

  function onChange() {
    setVerified(!verified);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const fd = new FormData();
    fd.append("full_name", formData.name);
    fd.append("company", formData.company);
    fd.append("email", formData.email);
    fd.append("phone", formData.phone);
    fd.append("message", formData.remark);

    try {
      const response = await fetch(
        `https://forms.hubspot.com/uploads/form/v2/143997603/b1221f66-8dd3-4ed1-a646-1fa6b10f80b1`,
        {
          method: "POST",
          body: fd,
        }
      );
      if (response.ok) {
        console.log("Form Submission successful");
      } else {
        console.log("Form Submission failed");
      }
    } catch (error) {
      // Handle error
    }
  };

  return (
    <div className="text-[#2D838E] pt-24 md:pt-28 lg:pt-32 max-w-screen-xl mx-auto">
      <h2 className="text-2xl md:text-4xl uppercase text-center font-black mt-4">
        KONTAKT
      </h2>
      <reCAPTCHA />
      <div className="bg-[#e0eced] dark:bg-[#272f44] p-8 rounded-md mt-6 mb-14 md:mb-20 lg:mb-32">
        <form onSubmit={handleContact} className="grid grid-cols-1 gap-6">
          <div className="grid grid-cols-2 gap-6">
            <input
              name="name"
              type="text"
              placeholder="Navn"
              required
              value={formData.name}
              onChange={handleChange}
              className="border-[1px] border-[#2d838e] focus:border-[#f29650] focus:rounded-none focus:outline-none p-5 bg-[#e0eced] dark:bg-[#272f44]"
            />
            <input
              name="company"
              type="text"
              placeholder="Firma"
              required
              value={formData.company}
              onChange={handleChange}
              className="border-[1px] border-[#2d838e] focus:border-[#f29650] focus:rounded-none focus:outline-none p-5 bg-[#e0eced] dark:bg-[#272f44]"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <input
              name="email"
              type="email"
              placeholder="Email"
              required
              value={formData.email}
              onChange={handleChange}
              className="border-[1px] border-[#2d838e] focus:border-[#f29650] focus:rounded-none focus:outline-none p-5 bg-[#e0eced] dark:bg-[#272f44]"
            />
            <input
              name="phone"
              type="text"
              placeholder="Telefonnummer"
              required
              value={formData.phonenumber}
              onChange={handleChange}
              className="border-[1px] border-[#2d838e] focus:border-[#f29650] focus:rounded-none focus:outline-none p-5 bg-[#e0eced] dark:bg-[#272f44]"
            />
          </div>
          <div className="grid grid-cols-1 gap-6">
            <textarea
              name="remark"
              type="textarea"
              placeholder="Comment/Remark"
              required
              value={formData.remark}
              onChange={handleChange}
              className="border-[1px] border-[#2d838e] focus:border-[#f29650] focus:rounded-none focus:outline-none p-5 bg-[#e0eced] dark:bg-[#272f44]"
            />
            <div className="flex gap-3 items-center">
              <input
                id="check"
                name="check"
                type="checkbox"
                required
                onChange={handleChecked}
                className="cursor-pointer w-5 h-5"
              />
              <label htmlFor="check">
                Jeg giver hermed InteGreat Aps tilladelse til at kontakte mig
                vedr InteGreat produkter. Jeg kan til enhver tid tilbagekalde
                dette ved at sende en email til: info@netsupport.dk'
              </label>
            </div>
          </div>

          <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA}
            onChange={onChange}
          />

          <button
            type="submit"
            className={`btn btn-primary rounded-full bg-[#f29650] text-white border-[#f29650] border-2 px-10 hover:bg-[#ffa954] hover:border-[#f29650] h-full text-lg font-medium ${!verified && "btn-disabled dark:bg-gray-400 !text-white"
              }`}
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
